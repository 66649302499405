export * from "ce/pages/Editor/IDE/Header/useLibraryHeaderTitle";
import useCELibraryHeaderTitle from "ce/pages/Editor/IDE/Header/useLibraryHeaderTitle";
import { getShowQueryModule } from "ee/selectors/moduleFeatureSelectors";
import { useSelector } from "react-redux";

function useLibraryHeaderTitle() {
  const ceLibraryHeaderTitle = useCELibraryHeaderTitle();
  const showQueryModule = useSelector(getShowQueryModule);

  if (showQueryModule) return "";

  return ceLibraryHeaderTitle;
}

export default useLibraryHeaderTitle;
